import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const navItems = ["Home", "About", "Contact"];
const socialMediaLinks = [
  {
    name: "Facebook",
    link: "https://www.facebook.com/AmericusBargainsAndTreasures/photos",
  },
  { name: "Twitter", link: "https://twitter.com" },
  { name: "Instagram", link: "https://instagram.com" },
];

const Footer = () => {
  return (
    <Box sx={styles.footer}>
      <Box sx={styles.footerContent}>
        <Box sx={styles.navContainer}>
          {navItems.map((item) => (
            <Link style={styles.link} key={item} to={`/${item.toLowerCase()}`}>
              <Button key={item} sx={styles.navButton}>
                {item}
              </Button>
            </Link>
          ))}
        </Box>
        <img
          src={require("../assets/images/logo.png")}
          alt="Logo"
          style={styles.logo}
        />
        <Box sx={styles.socialMediaContainer}>
          {socialMediaLinks.map((social) => (
            <a
              style={styles.socialLink}
              key={social.name}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {social.name}
            </a>
          ))}
        </Box>
        <a
          style={styles.copyright}
          href="https://24techdesign.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Copyright © 2023. Developed by{" "}
          <span
            style={{
              color: "orange",
            }}
          >
            @24TechDesign
          </span>
        </a>
      </Box>
    </Box>
  );
};

const styles = {
  footer: {
    backgroundColor: "#f8f8f8",
    color: "#fff",
    paddingTop: "40px",
  },
  footerContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "20px",
  },
  navContainer: {
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  link: {
    textDecoration: "none",
  },
  navButton: {
    color: "#712d10",
    fontWeight: "bolder",
  },
  logo: {
    width: "160px",
    height: "140px",
    cursor: "pointer",
  },
  socialMediaContainer: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    fontWeight: "bolder",
  },
  socialLink: {
    color: "#712d10",
    textDecoration: "none",
  },
  copyright: {
    color: "#712d10",
    textDecoration: "none",
    marginTop: "20px",
    fontWeight: "bolder",
  },
};

export default Footer;
