import React, { useEffect } from "react";
import { Box } from "@mui/material";
import styled, { keyframes } from "styled-components";
import PhotoSlider from "./PhotoSlider";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  animation: ${fadeIn} 1s ease forwards;
`;

const StyledLine = styled.div`
  position: relative;
  width: 30%;
  height: 2px;
  background-color: #712d10; /* Add your desired color here */
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #712d10;
  margin: 1rem 0;
  animation: ${slideIn} 1s ease forwards;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto 2rem;
  padding: 0 20px;
  text-align: justify;
  animation: ${fadeIn} 1s ease forwards;
`;

function About() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div style={{ marginTop: "70px" }}>
      <TitleWrapper>
        <StyledLine />
        <Title>About Us</Title>
        <StyledLine />
      </TitleWrapper>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          borderRadius: "16px",
          marginBottom: "2rem",
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
          padding: "20px",
        }}
      >
        <Paragraph>
          Welcome to Mattress Paradise & Furniture, your one-stop destination
          for timeless and elegant furniture pieces. At our store, we take pride
          in our craftsmanship, drawing from generations of knowledge and skill
          to create furniture that reflects both beauty and functionality.
          Whether you're furnishing a cozy corner or redesigning your entire
          home, we have everything you need to transform your space into a haven
          of comfort and style.
        </Paragraph>
      </Box>
      <PhotoSlider />
    </div>
  );
}

export default About;
