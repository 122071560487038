import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  padding: 20px;
`;

const DetailsButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  background-color: #e4790b;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #712d10; /* Darker green on hover */
  }
`;

const CategoryButtons = styled.div`
  display: flex;
  gap: 200px;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    gap: 2px;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const ProductCard = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out; /* Apply transition here */

  &:hover {
    transform: scale(1.05); /* Example transformation */
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  background-color: #f8f8f8;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e4a867;
    color: #333;
  }

  &.bg-primary-yellow {
    background-color: #e4a867;
    color: #333;
  }
`;

const BuyersChoice = () => {
  const [selectedCategory, setSelectedCategory] = useState(
    "mattresses" as "mattresses" | "furniture" | "appliances"
  );

  const data: Record<string, { name: string; image: any }[]> = {
    mattresses: [
      {
        name: "Innerspring Mattresses",
        image: require("../assets/images/mattresses/Innerspring.webp"),
      },
      {
        name: "Memory Foam",
        image: require("../assets/images/mattresses/MemoryFoam.webp"),
      },
      {
        name: "Pillow Top",
        image: require("../assets/images/mattresses/PillowTop.webp"),
      },
      {
        name: "Adjustable Air",
        image: require("../assets/images/mattresses/AdjustableAir.webp"),
      },
    ],
    furniture: [
      {
        name: "Factory Select Only",
        image: require("../assets/images/furniture/loganacorn.webp"),
      },
      {
        name: "Telluride Cafe",
        image: require("../assets/images/furniture/telluridecafe.webp"),
      },
      {
        name: "Porschia Taupe",
        image: require("../assets/images/furniture/porschiataupe.webp"),
      },
      {
        name: "Gypsy Driftwood",
        image: require("../assets/images/furniture/wypsywriftwood.webp"),
      },
    ],
    appliances: [
      {
        name: "Bottom Freezer Refrigerators",
        image: require("../assets/images/appliances/bottomfreezer.webp"),
      },
      {
        name: "French Door Refrigerators",
        image: require("../assets/images/appliances/frenchdoor.webp"),
      },
      {
        name: "Side by Side Refrigerators",
        image: require("../assets/images/appliances/sidebyside.webp"),
      },
      {
        name: "Top Freezer Refrigerators",
        image: require("../assets/images/appliances/topfreezer.webp"),
      },
      {
        name: "Top Freezer Refrigerators",
        image: require("../assets/images/appliances/small&undercounter.webp"),
      },
      {
        name: "Top Freezer Refrigerators",
        image: require("../assets/images/appliances/rvready.webp"),
      },
    ],
  };

  const handleCategoryChange = (
    category: "mattresses" | "furniture" | "appliances"
  ) => {
    setSelectedCategory(category);
  };

  return (
    <Container>
      <h3>Buyers' Choice</h3>
      <CategoryButtons>
        {["mattresses", "furniture", "appliances"].map((category) => (
          <Button
            key={category}
            onClick={() =>
              handleCategoryChange(
                category as "mattresses" | "furniture" | "appliances"
              )
            }
            className={selectedCategory === category ? "bg-primary-yellow" : ""}
          >
            {category.toUpperCase().replace("_", " ")}
          </Button>
        ))}
      </CategoryButtons>
      <ProductGrid>
        {data[selectedCategory].map((item: any) => (
          <ProductCard>
            <img src={item.image} alt={item.name} style={{ width: "100%" }} />
            <h4>{item.name}</h4>
            <Link to="/contact">
              <DetailsButton>Contact Us</DetailsButton>
            </Link>
          </ProductCard>
        ))}
      </ProductGrid>
    </Container>
  );
};

export default BuyersChoice;
