import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Section = styled.section`
  padding: 50px 0;
  animation: ${fadeIn} 1s ease forwards;
`;

const TitleWrapper = styled.div`
  position: relative;
  margin-bottom: 3rem;
`;

const StyledLine = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #712d10;
  left: 50%;
  width: 30%;
  transform: translateX(-50%);
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #712d10;
  text-align: center;
  z-index: 1;
  position: relative;
  margin-bottom: 30px;
  padding-top: 2rem;
  animation: ${slideIn} 1s ease forwards;
`;

const ContactInfo = styled.div`
  display: grid;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Card = styled.div`
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
  cursor: pointer;
  &:hover {
    transform: translateY(-5px);
  }
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  color: #712d10;
  margin-bottom: 10px;
`;

const CardContent = styled.p`
  font-size: 1rem;
  color: #666;
`;

const FacebookLink = styled.a`
  display: inline-block;
  color: #712d10;
  text-decoration: none;
`;

const FacebookIcon = styled.svg`
  width: 40px;
  height: auto;
  margin-bottom: 10px;
  fill: #712d10;
`;

const MapContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const MapFrame = styled.iframe`
  width: 100%;
  height: 400px;
  border: 0;
  border-radius: 10px;
`;

const Address = styled.p`
  margin-top: 30px;
  text-align: center;
  font-size: 1.2rem;
  color: #666;
`;

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const handlePhoneClick = () => {
    window.location.href = "tel:+12293800028";
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <Container>
        <Section>
          <TitleWrapper>
            <StyledLine />
            <Title>Contact Us</Title>
            <StyledLine />
          </TitleWrapper>
          <ContactInfo>
            <Card>
              <CardTitle>Phone</CardTitle>
              <CardContent
                onClick={handlePhoneClick}
                style={{ cursor: "pointer" }}
              >
                (229) 380-0028
              </CardContent>
            </Card>
            <Card>
              <CardTitle>Email</CardTitle>
              <CardContent>
                <a href="mailto:Yamenounalli@hotmail.com">
                  Yamenounalli@hotmail.com
                </a>
              </CardContent>
            </Card>
            <Card>
              <CardTitle>Hours</CardTitle>
              <CardContent>Mon-Sat: 9am - 5pm</CardContent>
              <CardContent>Sunday: Closed</CardContent>
            </Card>
            <Card>
              <CardContent>
                <FacebookLink href="https://www.facebook.com/AmericusBargainsAndTreasures">
                  <FacebookIcon
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 0C5.373 0 0 5.373 0 12c0 5.992 4.372 10.961 10.125 11.888v-8.389H7.063V12h3.062V9.48c0-3.004 1.79-4.668 4.532-4.668 1.313 0 2.758.235 2.758.235v3.03h-1.555c-1.526 0-2.002.948-2.002 1.922V12h3.406l-.547 3.5h-2.86v8.388C19.628 22.961 24 17.992 24 12c0-6.627-5.373-12-12-12"></path>
                  </FacebookIcon>
                </FacebookLink>
              </CardContent>
            </Card>
          </ContactInfo>
        </Section>
        <Section>
          <MapContainer>
            <MapFrame
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3306.2213996546016!2d-84.21962538479348!3d32.07161878030234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f2570e79325435%3A0x8bfeb6bb6d4bb67f!2s1114%20E%20Lamar%20St%2C%20Americus%2C%20GA%2031709!5e0!3m2!1sen!2sus!4v1713669691664!5m2!1sen!2sus"
              allowFullScreen
              loading="lazy"
            ></MapFrame>
          </MapContainer>
          <Address>
            Mattress Paradise & Furniture
            <br />
            Address: 1114 E Lamar St Americus, GA 31709
          </Address>
        </Section>
      </Container>
    </div>
  );
};

export default ContactUs;
