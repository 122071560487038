import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselItem = styled.div`
  text-align: center;
`;

const ImageContainer = styled.div`
  width: 450px; /* Adjust the width as needed */
  height: 250px; /* Adjust the height as needed */
  border-radius: 20px; /* Adjust the border-radius for curved corners */
  overflow: hidden; /* Ensure content is clipped to the rounded corners */
  margin-left: 18px; /* Adjust margin-left to center the images more to the right */

  @media (max-width: 768px) {
    width: 390px; /* Adjust the width for smaller screens */
    height: 150px; /* Adjust the height for smaller screens */
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const OurServicesWrapper = styled.div`
  margin-top: 5rem;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const SliderWrapper = styled.div`
  .slick-dots {
    bottom: -20px; /* Adjust the distance from the bottom as needed */
  }

  @media (max-width: 768px) {
    .slick-dots {
      bottom: -40px; /* Adjust the distance from the bottom on mobile views */
    }
  }
`;

const PhotoSlider: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    const importImages = async () => {
      const importedImages: string[] = [];
      // Define type for require.context
      type ImageContext = {
        keys: () => string[];
        <T>(id: string): T;
        resolve: (id: string) => string;
        id: string;
      };
      // Import all images from the ../assets/images/ directory
      const imageContext: ImageContext = (require as any).context(
        "../assets/images/gallery",
        false,
        /\.(webp)$/
      );
      // Iterate through each image in the context
      imageContext.keys().forEach((imageName: string) => {
        // Get the default export of the image
        const image: string = imageContext(imageName) as string;
        // Push the image to the importedImages array
        importedImages.push(image);
      });
      // Set the state with the imported images
      setImages(importedImages);
    };
    importImages();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 slides on larger screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(images.length, 1), // Show 1 slide on smaller screens
        },
      },
    ],
  };

  return (
    <OurServicesWrapper>
      <SliderWrapper>
        <Slider {...settings}>
          {images.map((image, index) => (
            <CarouselItem key={index}>
              <ImageContainer>
                <CarouselImage src={image} alt={`Service ${index + 1}`} />
              </ImageContainer>
            </CarouselItem>
          ))}
        </Slider>
      </SliderWrapper>
    </OurServicesWrapper>
  );
};

export default PhotoSlider;
