import React, { useEffect } from "react";
import styled from "styled-components";
import BuyersChoice from "./BuyersChoice"; // Import the BuyersChoice component
import Gallery from "./Gallery"; // Import the Gallery component

const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px; /* Adjust the margin-top value as needed */
  height: auto; /* Adjust height for better mobile responsiveness */
`;

const BackgroundContainer = styled.div`
  background-image: url(${require("../assets/images/gallery/loganacorn.webp")});
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  transition: transform 0.3s ease-in-out;
  background-size: cover; /* Update to cover the entire viewport */
  margin: 10px 0; /* Add margin top and bottom */
  height: 50vh; /* Adjust the height as needed */
  width: 100%;
}

  &:hover {
    transform: scale(1.1);
  }
`;

const Heading = styled.h2`
  margin: 10px 0; /* Add margin top and bottom */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    transition: transform 0.3s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    font-size: clamp(
      26px,
      2vw,
      24px
    ); /* Font size adjusts between 12px and 16px based on viewport width */
    text-align: center; /* Center text for smaller screens */
  }
`;

const MattressText = styled.span`
  color: #712d10;
  font-size: clamp(
    30px,
    6vw,
    32px
  ); /* Font size adjusts between 20px and 32px based on viewport width */
  margin-bottom: -30px; /* Adjust the negative margin to bring the lines closer */
  margin-top: 20px;
`;

const ParadiseText = styled.span`
  color: #e4790b;
  font-size: clamp(
    26px,
    6vw,
    24px
  ); /* Font size adjusts between 16px and 24px based on viewport width */
`;

const HomePage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <HomePageContainer>
      <BackgroundContainer />
      <Heading>
        <MattressText>MATTRESS</MattressText> <br />{" "}
        <ParadiseText>PARADISE & FURNITURE</ParadiseText>
      </Heading>
      <BuyersChoice />
      <Gallery />
    </HomePageContainer>
  );
};

export default HomePage;
