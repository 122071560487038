import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 2rem;
`;

const Title = styled.h3`
  font-size: 2rem;
  color: #000;
  text-align: center;
  margin-bottom: 2rem;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

function Gallery() {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    const importImages = async () => {
      const importedImages: string[] = [];
      // Define type for require.context
      type ImageContext = {
        keys: () => string[];
        <T>(id: string): T;
        resolve: (id: string) => string;
        id: string;
      };
      // Import all images from the ../assets/images/gallery directory
      const imageContext: ImageContext = (require as any).context(
        "../assets/images/gallery",
        false,
        /\.(webp)$/
      );
      // Iterate through each image in the context
      imageContext.keys().forEach((imageName: string) => {
        // Get the default export of the image
        const image: string = imageContext(imageName) as string;
        // Push the image to the importedImages array
        importedImages.push(image);
      });
      // Set the state with the imported images
      setImages(importedImages);
    };
    importImages();
  }, []);

  return (
    <Container>
      <Title>Photo gallery of our works</Title>
      <ImageGrid>
        {/* Render images using <Image> component */}
        {images.map((image, index) => (
          <Image key={index} src={image} alt={`Gallery Image ${index + 1}`} />
        ))}
      </ImageGrid>
    </Container>
  );
}

export default Gallery;
